<template>
<el-dropdown @command="handleItemClick">
    <span class="f-center">{{currentLang}}<i class="el-icon-arrow-down el-icon--right"></i></span>

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :command="i.value" v-for="i in langList" :key="i.value">{{i.label}}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { useI18n } from '@tci18n/vue2'
import { cloneDeep, find } from "lodash";


export default {
  name: 'SwitchLang',
  data () {
    return {
      langList: [],
      currentLang: 'English'
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    /**
     *@Description 初始化 数据 .
     *@Return
     **/
    initData() {
      const { getLangsList } = useI18n()
      this.langList = getLangsList()
      
      const crt = find(cloneDeep(this.langList), { value: window.__tci18n_locale__ || 'en-us' })
      if(crt && crt.label) {
        this.currentLang = crt.label
      } else this.currentLang = "English"
    },
    /**
     *@Description 搜索 框 事件 .
     *@Return
     **/
    handleItemClick(e) {
      
      window && window.lvzhiSwtichLangFn && window.lvzhiSwtichLangFn(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 110px;
  height: 32px;
  border-radius: 32px;
  padding: 0 10px;
  cursor: pointer;
  //color: #fff;
  border: 1px solid #497CF6;
}
</style>
