var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("error-content", {
    attrs: { code: "500", desc: "Oh～鬼知道服务器经历了什么～", src: _vm.src }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }